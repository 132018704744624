import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="FLOPPY WEBSITE" description="ALL HAIL THE FLOPPY DISK" />
    <Image>
      <p
        style={{
          margin: 0,
          color: "red",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "8vw",
          textShadow: "0.25vw 0.25vh 0 rgba(0, 0, 0, 0.4)",
        }}
      >
        THE CD-ROM,
        <br />
        THE ZIP DISK,
        <br />
        THE FLASH DRIVE
        <br />
        ARE FALSE GODS
        <br />
        ALL HAIL THE FLOPPY
        <br />
      </p>
    </Image>
    <p
      style={{
        textAlign: "center",
        backgroundColor: "#333339",
        fontWeight: "bold",
        margin: 0,
        padding: "1rem",
        color: "white",
      }}
    >
      MAINTAINED BY{" "}
      <a
        style={{ textDecoration: "none", color: "red" }}
        href="https://twitter.com/SuperGoodJared"
      >
        JARDO
      </a>
    </p>
  </Layout>
)

export default IndexPage
